import $ from 'jquery'

import 'jquery-mask-plugin'

import 'jquery-validation/dist/jquery.validate'
import 'jquery-validation/dist/additional-methods'
import 'jquery-validation/dist/localization/messages_uk'

import 'jquery-custom-select'

const API_URL = 'https://api.journalismaward.in.ua';
// const API_URL = 'http://api.local.journalism.com';
export default class Submit {
  constructor () {
    $.ajax({
      url: API_URL + '/nominations/index',
      method: 'GET',
      type: 'json',
      success: function (response) {
        let html = `<option value=""> Назва номінації </option>`;
        response.map( temp => {
            html += `<option value="${temp.label}">${ temp.label }</option>`;
        });
        $('#nomination').html(html);
        $('#nomination').customSelect();
        $('#material_type').customSelect();

        $('#nomination').change(function () {
          console.log($('#' + this.id + '_field').get(0))
          $('#' + this.id + '_field').
            attr('value', $(this).find('option:selected').val())
        })

        $('#material_type').change(function () {
          console.log($('#' + this.id + '_field').get(0))
          $('#' + this.id + '_field').
            attr('value', $(this).find('option:selected').val())
        })
      },
      error: function () {
        alert('server error!');
      },
    });
    
    const token = localStorage.getItem('token');
    if(token){
      // if browser has their auth token
      $.ajax({
        url: API_URL + '/check?token='+token,
        method: 'GET',
        type: 'json',
        success: function (response) {
          console.log(response)
          //if token is valid
          if(response !== false){
            $('#my_account_link').removeClass('hidden');
            $('#login_link').addClass('hidden');
            $('#signup_link').addClass('hidden');
            $('.submit_link').removeClass('hidden');
            $('.login_user').html(response.name);

            // $('#gender').customSelect();
            // $('#profile_gender').customSelect();

            //if current page is submit page
            if (location.pathname === '/submit' || location.pathname === '/profile'){
              $('#profile_name').val(response.name);
              $('#profile_email').val(response.email);
              $('#profile_gender').val(response.gender);
              $('#gender').val(response.gender);
              // $('#profile_gender').change();
              $('#profile_age').val(response.age);
              $('#profile_city').val(response.city);
              $('#profile_company_name').val(response.company_name);
              $('#profile_position').val(response.position);
              $('#profile_phone').val(response.phone);
              $('#phone_readonly').val(response.phone);
              $('#profile_experience').val(response.experience);
              $('#profile_id').val(response.id);
            }
          }
          else {
            if(location.pathname ==='/profile' || location.pathname === '/review'){
              location.href='/';
            }
            $('#my_account_link').addClass('hidden');
            $('#login_link').removeClass('hidden');
            $('#signup_link').removeClass('hidden');
          }
        },
        error: function () {
          alert('auth check error!');
        },
      })
    } else {
      //not login yet
      console.log('login: false')
      $('#my_account_link').addClass('hidden');
      $('.submit_link').addClass('hidden');
      $('#login_link').removeClass('hidden');
      $('#signup_link').removeClass('hidden');

      if(location.pathname ==='/profile' || location.pathname === '/review' || location.pathname === 'submit') {
        location.href = '/';
      }
    };  

    // gender custom select
    $('#gender').change(function () {
      console.log($('#' + this.id + '_field').get(0))
      $('#' + this.id + '_field').
        attr('value', $(this).find('option:selected').val())
    })

    $('#profile_gender').change(function () {
      console.log($('#' + this.id + '_field').get(0))
      $('#' + this.id + '_field').
        attr('value', $(this).find('option:selected').val())
    });

    // login and signup modal
    $(".login_link").click(function() {
      $("#popupwindow").show();
    });
    $(".signup-link").click(function() {
      $("#popupwindow").hide();
      $("#signupwindow").show();
    });

    window.onclick = function(event) {
      if (event.target == document.getElementById('popupwindow')) {
        $("#popupwindow").hide();
      }
      else if (event.target == document.getElementById('signupwindow')) {
        $("#signupwindow").hide();
      }
    }

    this.initLogin();

    if(location.pathname === '/resetpass') {
      $('.form_reset').validate({
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        focusInvalid: false,
        invalidHandler: this.error,
        rules: {
          email: {
            required: true,
            email: true
          },
        },
      });
      this.initReset();
    }

    $('input.phone-mask').mask('+38 (000) 000-00-00')

    $.validator.setDefaults({
      debug: true,
      success: 'valid',
    })

    jQuery.validator.addMethod('phone', function (value, element) {
      // allow any non-whitespace characters as the host part
      return this.optional(element) ||
        /^\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value)
    }, 'Використовуйте правильний формат телефону +38 (xxx) xxx-xx-xx')

    $.validator.addMethod("strongPassword", function(value, element) {
      return this.optional(element) || /^(?=.*\d).{14,}$/.test(value);
    }, "Please enter a password with at least 14 characters, including digits.");

    $('.form_login').validate({
      onfocusout: false,
      onkeyup: false,
      onclick: false,
      focusInvalid: false,
      invalidHandler: this.error,
      rules: {
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
        }
      },
    });

    $('#signupwindow .step1_form').validate({
      onfocusout: false,
      onkeyup: false,
      onclick: false,
      focusInvalid: false,
      invalidHandler: this.error,
      normalizer: true,
      rules: {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        gender: {
          required: true,
        },
        age: {
          required: true,
          digits: true,
          min: 16,
          max: 99,
        },
        password: {
          required: true,
          strongPassword: true
        },
        password_confirm: {
          required: true,
          equalTo: "#password"
        },
        city: {
          required: true,
        },
        company_name: {
          required: true,
        },
        position: {
          required: true,
        },
        phone: {
          required: true,
          phone: true,
        },
        experience: {
          required: true,
          digits: true,
          min: 0,
          max: 99,
        },
      },
    });

    $('#submit_step1.step1_form').validate({
      onfocusout: false,
      onkeyup: false,
      onclick: false,
      focusInvalid: false,
      invalidHandler: this.error,
      normalizer: true,
      rules: {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        gender_field: {
          required: true,
        },
        age: {
          required: true,
          digits: true,
          min: 16,
          max: 99,
        },
        city: {
          required: true,
        },
        company_name: {
          required: true,
        },
        position: {
          required: true,
        },
        phone: {
          required: true,
          phone: true,
        },
        experience: {
          required: true,
          digits: true,
          min: 0,
          max: 99,
        },
      },
    });

    $('.step2_form').validate({
      onfocusout: false,
      onkeyup: false,
      onclick: false,
      focusInvalid: false,
      invalidHandler: this.error,
      rules: {
        other_name: {
          required: false,
        },
        material_label: {
          required: true,
        },
        material_type: {
          required: true,
        },
        program_label: {
          required: true,
        },
        program_published_date: {
          required: true,
        },
        program_link: {
          required: true,
          url: true,
        },
        nomination: {
          required: true,
        },
        argument: {
          required: true,
        },
        awards: {
          required: true,
        },
      },
    })

    this.step1()
    this.step2()

    // Go to submit on home
    $('.goTo_submit').click(function() {
      if (token) { // if logged
        location.href = '/submit';
      } else {
        $("#popupwindow").show();
      }
    });

    //init material review page
    if(location.pathname === '/review') {
      $.ajax({
        url: API_URL + '/review/index?token='+token,
        method: 'GET',
        type: 'json',
        success: function (response) {
          console.log(response);
          let html = '';
          response.map((request,index) => {
            let date = new Date(request.created_at*1000);
            let created = date.toLocaleDateString();
            let resultStatus = '';
            switch (request.status) {
              case '0':
                resultStatus = 'прийнята розглядається'; // before review
                break;
              case '1':
                resultStatus = 'відхилено';    // Rejected
                break;
              case '2':
                resultStatus = 'прийнято та розглядається для прийняття остаточного рішення';   // accepted and reviewing
                break;
              case '3':
                resultStatus = 'не відповідає критеріям цієї номінації';   // does not meet criteria
                break;
              default:
                break;
            }
            html += `<tr>
              <th scope="row">${index+1}</th>
              <td>${request.material_label}</td>
              <td>${request.material_type}</td>
              <td>${request.program_link}</td>
              <td>${created}</td>
              <td>${request.nomination}</td>
              <td>${resultStatus}</td>
            </tr>`;
          })
          $('#review_body').html(html);
        },
        error: function (e) {
          console.error(e.responseText);
          alert('server error!');
        },
      })
    }

    // profile validator
    if (location.pathname === '/profile') {
      var profile_form = $('.profile_form');
      profile_form.validate({
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        focusInvalid: false,
        invalidHandler: this.error,
        rules: {
          name: {
            required: true,
          },
          age: {
            required: true,
          },
          gender_field: {
            required: true,
          },
          position: {
            required: true,
          },
          phone: {
            required: true,
          },
          email: {
            required: true,
            email: true
          },
        },
      });
      this.initProfile(profile_form);
    }

    this.check_mobileMenu( token ? true : false);
  } // constructor

  check_mobileMenu(logged) {
    if ($(window).width() < 1200) {
      if (logged) {
        if (!$('#login_link_mobile').hasClass('hidden')) {
          $('#login_link_mobile').addClass('hidden');
          $('#my_account_link_mobile').removeClass('hidden');
        }
      } else {
        if (!$('#my_account_link_mobile').hasClass('hidden')) {
          $('#my_account_link_mobile').addClass('hidden');
          $('#login_link_mobile').removeClass('hidden');
        }
      }
    }
  }

  step1 () {

    $('.form_step1 .btn-next').click(function (e) {
      e.preventDefault()

      let form = $('#submit_step1.step1_form')

      console.log('step 1 before validation !!!');

      if (form.valid()) {
        $('.form-group').removeClass('error')

        console.log('step1 validation success !!!');

        $('.form_step2').show()
        $('.form_step1').hide()
        $(document).scrollTop(400)
      }

      console.log('step 1 validation failed &&&');
    })

    let that = this;
    $('.form_step1 .btn-submit').click(function (e) {
      e.preventDefault()
      let form = $('.step1_form')
      // let gender = form.find('#gender').val()
      // let password = $('#password').val();
      // let password_confirm = $('#password_confirm').val();
      // if(password !== password_confirm){
      //   alert('password not match!');
      //   return;
      // }
      if (form.valid()) {
        $('.form-group').removeClass('error')
        that.signup();
      }
    });
  }

  step2 () {
    let that = this

    $('.form_step2 .btn-submit').click(function (e) {
      e.preventDefault()

      console.log('step2 before validation !!!');

      let form = $('.step2_form')

      if (form.valid()) {
        $('.form-group').removeClass('error')

        that.submit()
      }
      console.log('step2 after validation &&&');
    })
  }

  submit () {
    let step1Data = $('#submit_step1.step1_form').serializeArray()
    let step2Data = $('.step2_form').serializeArray()

    if (step1Data[3].value === '') {
      step1Data[3].value = step1Data[2].value;
    }
    
    let data = step1Data.concat(step2Data)

    let that = this

    $.ajax({
      url: API_URL + '/submit-request',
      method: 'POST',
      type: 'json',
      data: data,
      success: function (response) {
        that.afterSend()
      },
      error: function () {
        alert('Server error')
      },
    })
  }

  initLogin () {
    let that = this;
    $('.form_login .btn-submit').click(function (e) {
      e.preventDefault()
      let form = $('.form_login')
      if (form.valid()) {
        $('.form-group').removeClass('error')
        that.login();
      }
    })
  }

  signup () {
    let step1Data = $('.step1_form').serializeArray()
    let that = this
    $.ajax({
      url: API_URL + '/signup',
      method: 'POST',
      type: 'json',
      data: step1Data,
      success: function (response) {
        if(response === 'success'){
          alert('Signup Success!');
          that.afterSend()
        }
        else {
          alert(response);
        }
      },
      error: function (e) {
        console.log(e);
        alert('Server error')
      },
    })
  }
  
  login () {
    let formData = $('.form_login').serializeArray()
    let that = this
    $.ajax({
      url: API_URL + '/login',
      method: 'POST',
      type: 'json',
      data: formData,
      success: function (response) {
        if(response.token){
          localStorage.setItem('token',response.token);
          alert('Вхід успішно!');
          location.href="/";
        }
      },
      error: function (e) {
        if(e.responseJSON.message){
          alert(e.responseJSON.message)
        }
      },
    })
  }

  initProfile (profile_form) {
    let that = this;
    $('.profile_form .btn-submit').click(function (e) {
      e.preventDefault()
      // var profile_form = $('.profile_form');
      if (profile_form.valid()) {
        $('.form-group').removeClass('error')
        that.submitProfile(profile_form);
      }
    });
  }

  submitProfile (profile_form) {
    let formData = profile_form.serializeArray()
    let that = this
    $.ajax({
      url: API_URL + '/profile/update',
      method: 'POST',
      type: 'json',
      data: formData,
      success: function (response) {
        console.log(typeof response);
        if(response === true) {
          location.reload();
        }
      },
      error: function (e) {
        if(e.responseJSON.message){
          alert(e.responseJSON.message)
        }
      },
    })
  }

  initReset () {
    let that = this;

    $('.form_reset .btn-submit').click(function (e) {
      e.preventDefault()
      let form = $('.form_reset')
      if (form.valid()) {
        $('.form-group').removeClass('error')
        that.submitReset();
      }
    })
  }

  submitReset () {
    let formData = $('.form_reset').serializeArray()
    $.ajax({
      url: API_URL + '/reset/verify',
      method: 'POST',
      data: formData,
      success: function (res) {
        const response = JSON.parse(res);
        if (response.success !== 'false') {
          let email = formData[0].value;
          localStorage.setItem('reset_email',email);
          alert('Запит успішно відправлено!\nПеревір свою пошту.');
        } else {
          alert(response.msg);
        }
      },
      error: function (e) {
        if(e.responseJSON.message){
          alert(e.responseJSON.message)
        }
      },
    })
  }

  error (event, validator) {
    let errors = validator.errorMap

    $('.form-group').removeClass('error')

    $.each(errors, function (element, error) {
      $('.form-group[data-field=' + element + ']').addClass('error')
    })
  }

  afterSend () {
    if ($.session.set('show-popup', true)) {
      location.reload()
    }
  }
}
